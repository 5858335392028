<template>
  <VRow class="fill-height" align="stretch">
    <VCol cols="12">
      <KioskInstallDoneCard />
    </VCol>
  </VRow>
</template>

<script>
import KioskInstallDoneCard from './components/KioskInstallDoneCard'

export default {
  name: 'InstallationDone',
  components: {
    KioskInstallDoneCard
  },
  beforeRouteLeave(to, from, next) {
    this.$store.commit('kiosk/SET_PERMISSIONS', [])
    next()
  }
}
</script>
